import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import './App.css';
import {Button, OverlayTrigger, Popover, Alert, ButtonGroup, Table, ListGroup, Container, Row, Col, Form, Modal, Dropdown, DropdownButton, FormControl} from 'react-bootstrap';
import {BottomNav} from "./BottomNav"
import { FaPlus } from "react-icons/fa";
import {IoEllipsisVertical} from "react-icons/io5"
import {useHistoryState} from "./HistoryState"
import { useHistory } from "react-router-dom";
const API = require('./API');
const util = require('./Util');

export function Tools(props) {
    const history = useHistory();

    const [showNewModal, setShowNewModal] = useState(false);
    const [editTool, setEditTool] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [clickableElements, setClickableElements] = useState(true);
    const [categories, setCategories] = useState([]);
    const [tools, setTools] = useState([]);

    const [searchTerm, setSearchTerm] = useHistoryState("searchTerm", "");
    const [selectedKey, setSelectedKey] = useHistoryState("selectedKey", "-1");
    const [selectedTitle, setSelectedTitle] = useHistoryState("selectedTitle", "Alle Kategorien");
    const [admin, setAdmin] = useState((props.cookies.get("admin") === "true"));

    const [timer, setTimer] = useState(null);

    const [scrollRestored, setScrollRestored] = useState(false);
    const [pageYOffset, setPageYOffset] = useHistoryState("pageYOffset", 0);

    useEffect(() => {
        query();
        const timer = window.setInterval(() => {console.log("QUERY");query();}, util.interval);
        props.cookies.addChangeListener((el) => {
            if(el.name === "admin"){
                setAdmin(el.value);
            }
        })
        return () => {
            console.log("DESTROY")
            window.clearInterval(timer)
        }
    }, []);

    useEffect(() => {
        if(scrollRestored === false){
            window.scrollTo(0, pageYOffset);
            console.log("SCROLL");
            console.log(pageYOffset);
        }
            
        if(window.pageYOffset == pageYOffset){
            setScrollRestored(true);
        }
    }, [categories, tools]);

    function query(){
        API.getAllCategories((categories) => {
            setCategories(categories["data"]);
        });
        API.getAllTools((tools) => {
            setTools(util.sortByName(tools["data"]));
        });
    }

    function onCategorySelect(key, title){
        setSelectedTitle(title);
        setSelectedKey(key);
    }

    

    function onNewModalClose(changes){
        if(changes){
            query();
        }
        setShowNewModal(false);
        setEditTool(null);
    }

    function onToolSelect(id){
        setPageYOffset(window.pageYOffset);
        history.push("/tool?id=" + id);
    }

    function onEdit(tool){
        setEditTool(tool);
        setShowNewModal(true);
    }

    function onDelete(id){
        if (window.confirm('Hiermit wird das Werkzeug gelöscht. Alle verbundenen Verleihe werden gelöscht. Fortfahren?')) {
            API.deleteTool(parseInt(id), () => {query()})
        }
    }
    

    return (
        
        <div>
            <Table className="text-light bg-dark py-2">
                <tbody>
                <tr>
                    <td colSpan={3} className="align-middle border-top-0 py-0">
                        <h4 className="m-0">
                            Werkzeuge
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td className="align-middle border-top-0 pr-1 py-2 w-50">
                        <Dropdown as={ButtonGroup} variant="outline-success" className="w-100" id="dropdown-basic-button" >
                            <Dropdown.Toggle variant="outline-success" id="dropdown-custom-1">{selectedTitle}</Dropdown.Toggle>
                            <Dropdown.Menu variant="outline-success" >
                            <Dropdown.Item style={{width:"100%"}} key={"-1"} eventKey={"-1"} onSelect={(e) => {onCategorySelect("-1", "Alle Kategorien")}}>Alle Kategorien</Dropdown.Item>
                            {categories ? categories.map((row) => 
                                <Dropdown.Item style={{width:"100%"}} onSelect={(e) => {onCategorySelect(e, row["NAME"])}} key={row["ID"]} eventKey={row["ID"]} >{row["NAME"]}</Dropdown.Item>
                            ) : "" }
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td className="align-middle border-top-0 pl-1 py-2 w-50">
                        <FormControl className="w-100" type="text" placeholder="Suche" value={searchTerm} onChange = {(e) => {setSearchTerm(e.target.value)}} />
                    </td> 
                    <td className="d-none d-md-block align-middle border-top-0 py-2" style={{width: "200px"}}>
                        <Button 
                            variant="outline-success" 
                            onClick={() => setShowNewModal(true)}
                        >Neues Werkzeug</Button>
                    </td>
                </tr>
                </tbody>
            </Table>
            <div style={{paddingBottom: '96px'}}> 
                <Container fluid>
                    <Row>
                        <Col>
                            <ListGroup style={clickableElements === false ? {pointerEvents: "none"} : {}}>
                            { tools.length > 0 ?
                                tools.filter(row => (
                                    (searchTerm === "" || row["NAME"].toLowerCase().includes(searchTerm.toLowerCase())) &&
                                    (selectedKey === "-1" || selectedKey === row["CATEGORY_ID"])
                                )).map(row =>
                                    <ListGroup.Item action as="button" key={row["ID"]} eventKey={row["ID"]} onClick={() => onToolSelect(row["ID"])}>
                                        <Table className="mb-0">
                                        <tbody>
                                        <tr>
                                            <td className="text-left border-top-0">
                                                {row["NAME"]}
                                            </td>
                                            <td className="text-right border-top-0">
                                                <OverlayTrigger
                                                    trigger="focus"
                                                    key='bottom'
                                                    placement='bottom'
                                                    onToggle={(e) => {setClickableElements(!e)}}
                                                    overlay={
                                                        <Popover id={`popover-positioned-bottom`}>
                                                        <Popover.Content>
                                                            <Button size="sm" className="w-100 mb-1" variant="outline-success" onClick={(e) => {e.stopPropagation();onEdit(row);}}>Bearbeiten</Button><br/>
                                                            <Button size="sm" className="w-100" variant="outline-success" onClick={(e) => {e.stopPropagation();onDelete(row["ID"]);}}>Löschen</Button>
                                                        </Popover.Content>
                                                        </Popover>
                                                    }
                                                    >
                                                    <Button className="bg-white" size="sm" variant="light" onClick={(e) => {e.stopPropagation()}}>
                                                        <IoEllipsisVertical/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </Table>
                                    </ListGroup.Item>
                                ) : "Keine Einträge vorhanden" }
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <a className="float d-block d-md-none" onClick={() => setShowNewModal(true)}>
                    <FaPlus className="my-float"/>
                </a>
                <ToolEditor show={showNewModal} edit={editTool} categories={categories} defaultCategory={selectedKey} onClose={(changes) => onNewModalClose(changes)}/>
            </div>
            <BottomNav path="/tools"/>
        </div>
    );
}


function ToolEditor(props) {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [category, setCategory] = useState(-1);
    const [categoryTitle, setCategoryTitle] = useState("");
    const [amount, setAmount] = useState(1);
    const [show, setShow] = useState(props.show);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
  
    useEffect(() => {
      setShow(props.show);
      setCategory(props.categories);
      if(props.edit){
        setId(props.edit["ID"]);
        setName(props.edit["NAME"]);
        setAmount(props.edit["AMOUNT"]);
        setCategory(props.edit["CATEGORY_ID"]);
        setCategoryTitle(props.categories.find(x => x["ID"] == props.edit["CATEGORY_ID"])["NAME"]);
      } else {
        if(props.categories.length > 0){
            if(props.defaultCategory !== "-1"){
                setCategory(props.defaultCategory);
                setCategoryTitle(props.categories.find(x => x["ID"] == props.defaultCategory)["NAME"]);
            } else {
                setCategory(props.categories[0]["ID"]);
                setCategoryTitle(props.categories[0]["NAME"]);
            }
        }
      }
    }, [props.show, props.categories, props.defaultCategory, props.edit]);
    
    const onNewTool = () => {
      if(name === ""){
          setAlertMessage("Bitte gib einen gültigen Werkzeugnamen ein");
          setShowAlert(true);
      }
      else if(amount < 1){
        setAlertMessage("Bitte gib eine Anzahl >= 1 ein");
        setShowAlert(true);
      } else {
          if(props.edit){
            if(amount < (parseInt(props.edit["AMOUNT"]) - parseInt(props.edit["FREE"]))){
                setAlertMessage("Anzahl darf nicht kleiner sein, als die Anzahl der zurzeit ausgeliehen Menge (=" + 
                    (parseInt(props.edit["AMOUNT"]) - parseInt(props.edit["FREE"])) + ")");
                setShowAlert(true);
            } else {
                API.updateTool(id, name, amount, category, onInsertCallback);
            }
          } else {
            API.insertTool(name, amount, category, onInsertCallback);
          }
      }
    };

    const onInsertCallback = (cb) => {
        handleClose(true);
    }

    const handleClose = (changes) => {
        props.onClose(changes);
        setId("");
        setName("");
        setAmount(1);
    }
    
    return (
      <>
        <Modal show={show} onHide={() => handleClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Neues Werkzeug</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                        {alertMessage}
                    </Alert>
                    <Form.Label>Kategorie</Form.Label>
                    <DropdownButton variant="dark" id="dropdown-basic-button" title={categoryTitle} className="mr-1" >
                        {props.categories ? props.categories.map((row) => 
                            <Dropdown.Item onSelect={(e) => {setCategory(row["ID"]); setCategoryTitle(row["NAME"])}} key={row["ID"]} eventKey={row["ID"]} >{row["NAME"]}</Dropdown.Item>
                        ) : "" }
                    </DropdownButton>
                    <Form.Label>Name</Form.Label>
                    <FormControl type="text" value={name} onChange={(e)=>{setName(e.target.value)}}></FormControl>
                    <Form.Label>Anzahl{props.edit ? " (verfügbar " + props.edit["FREE"] + "/" + props.edit["AMOUNT"] + ")" : ""}</Form.Label>
                    <FormControl type="number" value={amount} onChange={(e)=>{setAmount(e.target.value)}}></FormControl>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onNewTool()}>Speichern</Button>
            </Modal.Footer>
        </Modal>
      </>
    );
  }