import React, {useState, useContext} from 'react';
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import './App.css';
import {Button, Table, Modal, Alert, Form, Dropdown, DropdownButton, FormControl, Card, Accordion, AccordionContext, Container, Row, Col} from 'react-bootstrap';

import { IconContext } from "react-icons";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import Cookies from 'universal-cookie';
import {BottomNav} from "./BottomNav";
import {ReturnToolComp} from "./ReturnTool";
 
const cookies = new Cookies();
const API = require('./API');
const util = require('./Util');

export class Tool_ extends React.Component{

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };

    constructor(){
        super();
        this.state = {
            histories: null,
            tool: null,
            sites: [],
            showNewModal: false,
            showAlert: false,
            alertMessage: "",
            selectedAmount: 1,
            selectedSite: null,
            selectedSiteTitle: "",
            timer: null
        }
    }

    componentDidMount(){
        this.query();
        this.setState({
            timer: window.setInterval(() => {this.query()}, util.interval)
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.timer);
    }

    query(){
        var params = new URLSearchParams(this.props.location.search);
        var id = params.get("id");
        API.getToolById(id, (resp) => {this.setState({tool: resp["data"][0]})});
        API.getHistoriesByToolId(id, (resp) => {console.log(this.sortHistories(resp["data"]));this.setState({histories: this.sortHistories(resp["data"])})});
        API.getAllSites((resp) => {this.setState({sites: util.sortByName(resp["data"]).filter((x) => x.ARCHIVED == 0)})});
    }

    sortHistories(histories){
        if (histories === undefined){
            return null;
        }

        // Sortieren nach Wert
        return histories.sort((a,b) => {
            var doneA = a["AMOUNT"] <= 0;
            var doneB = b["AMOUNT"] <= 0;

            var nameA = Math.min(...(a["transactions"].map((x) => x["DATE"])));
            var nameB = Math.min(...(b["transactions"].map((x) => x["DATE"])));
    
            if(doneA > doneB){
                return 1;
            }
            else if(doneA < doneB){
                return -1;
            }
            else{
                if (nameA > nameB) {
                    return -1;
                }
                else if (nameA < nameB) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }

    showNewModal(){
        this.setState({
            selectedAmount: 1,
            selectedSite: this.state.sites.length > 0 ? this.state.sites[0]["ID"] : null,
            selectedSiteTitle: this.state.sites.length > 0 ? this.state.sites[0]["NAME"] : "",
            showNewModal: true
        });
    }

    onRent(){
        if(this.state.selectedAmount > parseInt(this.state.tool["FREE"])){
            this.setState({
                showAlert: true,
                alertMessage: "Anzahl ist leider nicht verfügbar"
            });
        } else if(this.state.selectedAmount < 1){
            this.setState({
                showAlert: true,
                alertMessage: "Bitte gib eine Anzahl >= 1 ein"
            });
        } else {
            API.lendTool(
                parseInt(this.state.tool["ID"]), 
                parseInt(this.state.selectedSite), 
                cookies.get("name"), 
                parseInt(Date.now() / 1000), 
                this.state.selectedAmount, 
                () => {this.onRentCompletion()}
                );
            this.setState({showNewModal: false});
        }
    }

    onRentCompletion(){
        this.query();
    }

    onReturnTool(){
        this.query();
    }
    render(){
        return (
            <div>
                <Table className="text-light bg-dark">
                    <tbody>
                    <tr>
                        <td colSpan={2} className="align-middle text-left border-top-0 pb-0">
                            <h5 className="mb-0">{this.state.tool ? this.state.tool["NAME"] : ""}</h5>
                        </td>
                    </tr>
                    <tr>
                        <td className="align-middle border-top-0 pt-1">
                            {this.state.tool ? "Verfügbar " + this.state.tool["FREE"] + "/" + this.state.tool["AMOUNT"] : ""}
                        </td>
                        {(this.state.tool && this.state.tool["FREE"] > 0) ?
                        <td className="align-middle border-top-0 pt-1">
                            <Button variant="outline-success" onClick={() => this.showNewModal()}>
                                Werkzeug ausleihen
                            </Button>
                        </td>
                        : "" }
                    </tr>
                    </tbody>
                </Table>
                <div style={{paddingBottom: '80px'}}>
                <Accordion className="mx-3">
                    {this.state.histories ? this.state.histories.map(history =>
                        <Card className="my-2 mx-auto">
                            <util.CustomToggleAcc eventKey={String(history["SITE_ID"])} name={history["SITE_NAME"]} taken={history["AMOUNT"]}/>
                            <Accordion.Collapse eventKey={String(history["SITE_ID"])}>
                                <Card.Body style={{padding: "0"}}>
                                    <Table style={{marginBottom: "0"}}>
                                        <tbody>
                                            <tr>
                                                <th className="text-left">Ausgeliehen</th>
                                            </tr>
                                            {history["transactions"].filter((transaction) => transaction["AMOUNT"] > 0 && transaction["TYPE"] == 0).map((transaction)=>
                                                <tr>
                                                    <td className="w-100">
                                                    <Container fluid>
                                                        <Row>
                                                            <Col>
                                                                {transaction["EMPLOYEE"]}
                                                            </Col>
                                                            <Col>
                                                                {util.from_timetamp(transaction["DATE"])}
                                                            </Col>
                                                            <Col>
                                                                {transaction["AMOUNT"]}
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th className="text-left">Zurückgegeben</th>
                                            </tr>
                                            {history["transactions"].filter((transaction) => transaction["AMOUNT"] < 0 && transaction["TYPE"] == 1).map((transaction)=>
                                                <tr>
                                                    <td>
                                                        <Container fluid>
                                                            <Row>
                                                                <Col>
                                                                    {transaction["EMPLOYEE"]}
                                                                </Col>
                                                                <Col>
                                                                    {util.from_timetamp(transaction["DATE"])}
                                                                </Col>
                                                                <Col>
                                                                    {-1 * transaction["AMOUNT"]}
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </td>
                                                </tr>
                                            )}
                                            {history["transactions"].filter((transaction) => transaction["TYPE"] == 2).length > 0 ?
                                            <tr>
                                                <th className="text-left">Überschuss</th>
                                            </tr>
                                            : ""}
                                            {history["transactions"].filter((transaction) => transaction["TYPE"] == 2).map((transaction)=>
                                                <tr>
                                                    <td>
                                                        <Container fluid>
                                                            <Row>
                                                                <Col>
                                                                    {transaction["EMPLOYEE"]}
                                                                </Col>
                                                                <Col>
                                                                    {util.from_timetamp(transaction["DATE"])}
                                                                </Col>
                                                                <Col>
                                                                    {transaction["AMOUNT"]}
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </td>
                                                </tr>
                                            )}
                                            {history["AMOUNT"] > 0 ? 
                                                <tr>
                                                    <td>
                                                        <ReturnToolComp toolid={history["TOOL_ID"]} siteid={history["SITE_ID"]} amount={history["AMOUNT"]} onReturn={() => this.onReturnTool()}/>
                                                    </td>
                                                </tr>
                                            : ""}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ) : "Keine Einträge vorhanden"}
                </Accordion>
                </div>
                <Modal show={this.state.showNewModal} onHide={() => {this.setState({showNewModal: false})}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Werkzeug ausleihen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Alert variant="danger" show={this.state.showAlert} onClose={() => this.setState({showAlert: false})} dismissible>
                        {this.state.alertMessage}
                    </Alert>
                    <Form.Label>Baustelle</Form.Label>
                    <DropdownButton variant="dark" id="dropdown-basic-button" title={this.state.selectedSiteTitle} className="mr-1" >
                        {this.state.sites ? this.state.sites.map((row) => 
                            <Dropdown.Item onSelect={(e) => {this.setState({selectedSite: row["ID"]}); this.setState({selectedSiteTitle: row["NAME"]});}} key={row["ID"]} eventKey={row["ID"]} >{row["NAME"]}</Dropdown.Item>
                        ) : "" }
                    </DropdownButton>
                    {(this.state.tool && this.state.tool["AMOUNT"] > 1) ?
                        <>
                            <Form.Label>Menge (verfügbar {this.state.tool["FREE"]}/{this.state.tool["AMOUNT"]})</Form.Label>
                            <FormControl type="number" value={this.state.selectedAmount} onChange={(e) => this.setState({selectedAmount: e.target.value})} />
                        </>
                    : ""}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({showNewModal: false})}>
                        Abbruch
                    </Button>
                    <Button variant="primary" onClick={() => this.onRent()}>
                        Ausleihen
                    </Button>
                    </Modal.Footer>
                </Modal>
                <BottomNav path="/tools"/>
            </div>
        );
    }
}

export const Tool = withRouter(Tool_);