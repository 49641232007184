import React, {useState, useEffect} from 'react';
import './App.css';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tools} from './Tools'
import {Sites} from './Sites'
import {Tool} from './Tool'
import {Site} from './Site'
import {
  Switch,
  Route
} from "react-router-dom";
import {Button, Navbar, Nav, Alert, Modal, FormControl} from 'react-bootstrap';
import { MdEdit } from "react-icons/md";
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();

const API = require('./API');

class App extends React.Component{

  constructor(){
    super();
    this.state = {
      nameSet: false,
      unlocked: false
    }
  }

  componentDidMount(){
    if(cookies.get("name") !== undefined){
      this.setState({nameSet: true});
    } else {
      cookies.addChangeListener((e) => {
        if(e.name === "name" && e.value !== ""){
          this.setState({nameSet: true});
        }
      });
    }
    if(cookies.get("unlocked") !== undefined && cookies.get("unlocked") === "true"){
      this.setState({unlocked: true});
    } else {
      cookies.addChangeListener((e) => {
        if(e.name === "unlocked" && e.value !== "" && e.value === true){
          this.setState({unlocked: true});
        }
      });
    }
    
    window.scrollTo(0,732)
  }


  render(){
    if(this.state.nameSet === false || this.state.unlocked === false){
      return(
        <NameSetter show={true}/>
      )
    } else {
      return (
        <div className="App">
            <Header/>  
            <Switch>               
              <Route path='/tools'>
                <Tools cookies={cookies}/>
              </Route>   
              <Route path='/sites'>
                <Sites cookies={cookies}/>
              </Route>
              <Route path='/site'>
                <Site/>
              </Route>
              <Route path='/tool'>
                <Tool cookies={cookies}/>
              </Route>
              <Route path='/'>
                <Tools cookies={cookies}/>
              </Route>           
            </Switch>
        </div>
      );
    }
  }
}

class Header_ extends React.Component{

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(){
    super();
    this.state = {
      showNameSetter: false,
    }
  }

  onClick(link){
    this.props.history.push(link);
  }


  render(){
    /*   
    <Form inline>
    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
    <Button variant="outline-info">Quicksearch</Button>
    <Button variant="outline-info">Advanced</Button>
    </Form>
    */
    return(
      <header className="App-header">
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/">
            <img
              src="./assets/logo-boegl-190px.png"
              width="100"
              className="d-inline-block align-top"
              alt="Boegl logo"
            />
          </Navbar.Brand>
            <Nav className="d-none d-md-flex">
                <Nav.Link onClick={() => {this.onClick("/tools")}}>Werkzeuge</Nav.Link>
                <Nav.Link onClick={() => {this.onClick("/sites")}}>Baustellen</Nav.Link>
            </Nav>
            <Navbar.Text className="ml-auto mr-2">{cookies.get("name")}</Navbar.Text>
            <Button size="sm" variant="outline-success" onClick={() => {this.setState({showNameSetter: true})}}>
              <MdEdit/>
            </Button>
          </Navbar>
          <NameSetter show={this.state.showNameSetter} onClose={() => {this.setState({showNameSetter: false})}}/>
      </header>
    );
  }
}
const Header = withRouter(Header_);



function NameSetter(props) {
  const [name, setName] = useState(cookies.get("name") ? cookies.get("name") : "");
  const [password, setPassword] = useState(cookies.get("pw") ? cookies.get("pw") : "");
  const [show, setShow] = useState(props.show);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
  
  const onSave = () => {
    var aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    if(name === ""){
      setAlertMessage("Bitte gib einen gültigen Namen ein");
      setShowAlert(true);
    } else {
      if(password === ""){
        setAlertMessage("Bitte gib ein Passwort ein");
        setShowAlert(true);
      } else {
        API.validatepw(password, (res) => {
          if(res["data"]["ver"] !== true){
            setAlertMessage("Passwort ist leider falsch");
            setShowAlert(true);
          } else {
            cookies.set("unlocked", res["data"]["ver"], {expires: aYearFromNow});
            cookies.set("admin", res["data"]["adm"], {expires: aYearFromNow} )
            cookies.set("name", name, {expires: aYearFromNow});
            if(props.onClose){
              props.onClose();
            }
          }
        });
      }
    }
  };
  
  return (
    <>
      <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={() => {if(props.onClose){
            props.onClose();
          }}}
        >
          <Modal.Header closeButton>
            <Modal.Title>Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                {alertMessage}
            </Alert>
            Name
            <FormControl type="text" value={name} onChange={(e)=>{setName(e.target.value)}}></FormControl>
            <>
              Passwort
              <FormControl type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}></FormControl>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => onSave()}>Speichern</Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}



export default App;

