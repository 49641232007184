import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import './App.css';
import {Button, Table, Modal, Alert, ListGroup, Container, Row, Col, Form, FormControl,OverlayTrigger, Popover, Badge} from 'react-bootstrap';
import {BottomNav} from "./BottomNav";
import { FaPlus } from "react-icons/fa";
import {IoEllipsisVertical} from "react-icons/io5"
import {useHistoryState} from "./HistoryState"
import { useHistory } from "react-router-dom";

const API = require('./API');
const util = require('./Util');

export function Sites(props){

    const history = useHistory();

    const [showNewModal, setShowNewModal] = useState(false);
    const [clickableElements, setClickableElements] = useState(true);
    const [sites, setSites] = useState([]);
    const [searchTerm, setSearchTerm] = useHistoryState("searchTerm", "");
    const [editSite, setEditSite] = useState(null);
    const [admin, setAdmin] = useState((props.cookies.get("admin") === "true"));
    const [showArchived, setShowArchived] = useHistoryState(false);
    
    const [scrollRestored, setScrollRestored] = useState(false);
    const [pageYOffset, setPageYOffset] = useHistoryState("pageYOffset", 0);

    useEffect(() => {
        query();
        const timer = window.setInterval(() => {query()}, util.interval);
        props.cookies.addChangeListener((el) => {
            if(el.name === "admin"){
                setAdmin(el.value);
            }
        });
        return () => {
            console.log("DESTROY");
            window.clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if(scrollRestored === false){
            window.scrollTo(0, pageYOffset);
            console.log("SCROLL");
            console.log(pageYOffset);
        }
            
        if(window.pageYOffset == pageYOffset){
            setScrollRestored(true);
        }
    }, [sites]);

    function query(){
        API.getAllSites((sites) => {setSites(util.sortByName(sites["data"]))});
    }

    function onNewModalClose(changes){
        if(changes){
            query();
        }
        setEditSite(null);
        setShowNewModal(false);
    }

    function onSiteSelect(id){
        setPageYOffset(window.pageYOffset);
        history.push("/site?id=" + id);
    }

    function onEdit(site){
        setEditSite(site);
        setShowNewModal(true);
    }

    function onArchive(site){
        if (window.confirm('Archivieren bedeuted, dass die Baustelle auf "fertig" gesetzt wird und nicht mehr in der Liste erscheint. Alle Werkzeuge dieser Baustelle werden automatisch zurückgegeben. Fortfahren?')) {
            API.archiveSite(parseInt(site["ID"]), props.cookies.get("name"), parseInt(Date.now() / 1000), () => {query()});
          }
    }

    return (
        
        <div>
            <Table className="text-light bg-dark py-2">
                <tbody>
                <tr>
                    <td colSpan={2} className="align-middle border-top-0 py-0">
                        <h4 className="m-0">
                            Baustellen
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td className="align-middle border-top-0 w-100 py-2">
                        <FormControl className="w-100" type="text" placeholder="Suche" value={searchTerm} onChange = {(e) => {setSearchTerm(e.target.value)}} />
                    </td>
                    {admin == true ?
                    <td className="align-middle border-top-0 py-2">  
                        <Form.Check 
                            checked={showArchived}
                            onChange={(e) => {if(e.target.checked) {setShowArchived(true)} else {setShowArchived(false)}}}
                            type="switch"
                            id="custom-switch"
                            label="Archiviert"
                        />
                    </td> : ""
                    }
                    <td className="d-none d-md-block align-middle border-top-0 py-2" style={{width: "200px"}}>
                        <Button 
                        className="d-none d-md-block"
                        variant="outline-success"
                        onClick={() => setShowNewModal(true)}
                    >Neue Baustelle</Button>
                    </td>
                </tr>
                </tbody>
            </Table>
            <div style={{paddingBottom: '96px'}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <ListGroup style={clickableElements === false ? {pointerEvents: "none"} : {}}>
                            {sites.length > 0 ?
                                sites
                                .filter(row => ((showArchived || row["ARCHIVED"] == 0) && (searchTerm === "" || row["NAME"].toLowerCase().includes(searchTerm.toLowerCase()))))
                                .map(row =>
                                    <ListGroup.Item action 
                                        as="button" 
                                        key={row["ID"]} 
                                        eventKey={row["ID"]} 
                                        onClick={() => onSiteSelect(row["ID"])}>
                                        <Table className="mb-0">
                                        <tbody>
                                        <tr>
                                            <td className="text-left border-top-0 align-middle">
                                                {row["NAME"]}
                                            </td>
                                            <td className="text-left border-top-0 align-middle">
                                                {row["ARCHIVED"] == 1 ?
                                                    <h5 className="mb-0"><Badge className="ml-2" variant="secondary">Archiviert</Badge></h5>
                                                : ""}
                                            </td>
                                            <td className="text-right border-top-0">
                                                <OverlayTrigger
                                                    trigger="focus"
                                                    key='bottom'
                                                    placement='bottom'
                                                    onToggle={(e) => {setClickableElements(!e)}}
                                                    overlay={
                                                        <Popover id={`popover-positioned-bottom`}>
                                                        <Popover.Content>
                                                            <Button className="w-100 mb-1" size="sm" variant="outline-success" onClick={(e) => {e.stopPropagation();onEdit(row);}}>Bearbeiten</Button><br/>
                                                            {admin ? 
                                                            <Button className="w-100" size="sm" variant="outline-success" onClick={(e) => {e.stopPropagation();onArchive(row);}}>Archivieren</Button>
                                                            : ""}
                                                        </Popover.Content>
                                                        </Popover>
                                                    }
                                                    >
                                                    <Button size="sm" className="bg-white" variant="light" onClick={(e) => {e.stopPropagation();}}>
                                                        <IoEllipsisVertical/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </Table>
                                    </ListGroup.Item>
                                ) : "Keine Einträge vorhanden" }
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <a className="float d-block d-md-none" onClick={() => {setShowNewModal(true)}}>
                <FaPlus className="my-float"/>
            </a>
            <SiteEditor show={showNewModal} edit={editSite} onClose={(changes) => onNewModalClose(changes)}/>
            <BottomNav path="/sites"/>
        </div>
    );
}

function SiteEditor(props) {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [show, setShow] = useState(props.show);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
  
    useEffect(() => {
        if(props.edit){
            setId(props.edit["ID"]);
            setName(props.edit["NAME"]);
        }
        setShow(props.show);
    }, [props.show, props.edit]);
    
    const onSiteSubmit = () => {
        if(name === ""){
            setAlertMessage("Bitte gib einen gültigen Baustellennamen ein");
            setShowAlert(true);
        } else {
            if(props.edit){
                API.updateSite(name, parseInt(id), onInsertCallback);
            } else {
                API.insertSite(name, onInsertCallback);
            }
        }
    };

    const onInsertCallback = (cb) => {
        handleClose(true);
    }

    const handleClose = (changes) => {
        props.onClose(changes);
        setId("");
        setName("");
    }
    
    return (
      <>
        <Modal show={show} onHide={() => handleClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Neue Baustelle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                        {alertMessage}
                    </Alert>
                    <Form.Label>Name</Form.Label>
                    <FormControl type="text" value={name} onChange={(e)=>{setName(e.target.value)}}></FormControl>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onSiteSubmit()}>Speichern</Button>
            </Modal.Footer>
        </Modal>
      </>
    );
  }
  

  