
//const API_endpoint = "http://localhost:8080/api.php";
const API_endpoint = "/api/api.php";

function callRPC(func, body, callback){
    var jsonReq = {
        "fname": func,
        "data": body
    }
    fetch(API_endpoint, {
        method: 'POST',
        body: JSON.stringify(jsonReq)
        })
        .then((response) => {return response.json()})
        .then((jsonResp) => {
            if(jsonResp["error"] === true){
                alert("Sorry, something happened: " + jsonResp["message"]);
            } else {
                callback(jsonResp);
            }
        });
}



//----------------------------------------  CATEGORY  ---------------------------------------------
export function getAllCategories(callback) {
    callRPC("getAllCategories", {}, callback);
}

//----------------------------------------  TOOLS  ---------------------------------------------
export function getAllTools(callback) {
    callRPC("getAllTools", {}, callback);
}

export function getToolById(id, callback) {
    callRPC("getToolById", {id: id}, callback);
}

export function insertTool(name, amount, category, callback) {
    callRPC("insertTool", {name: name, amount: amount, category: category}, callback);
}

export function updateTool(id, name, amount, category, callback) {
    callRPC("updateTool", {id: id, name: name, amount: amount, category: category}, callback);
}

export function deleteTool(id, callback) {
    callRPC("deleteTool", {id: id}, callback);
}

//----------------------------------------  SITES  ---------------------------------------------

export function getAllSites(callback) {
    callRPC("getAllSites", {}, callback);
}

export function getSiteById(id, callback) {
    callRPC("getSiteById", {id: id}, callback);
}

export function insertSite(name, callback) {
    callRPC("insertSite", {name: name}, callback);
}

export function updateSite(name, id, callback) {
    callRPC("updateSite", {name: name, id: id}, callback);
}

export function archiveSite(id, employee, date, callback){
    callRPC("archiveSite", {id: id, employee: employee, date: date}, callback);
}

//----------------------------------------  HISTORY  ---------------------------------------------

export function getHistoriesByToolId(toolid, callback) {
    callRPC("getHistoriesByToolId", {toolid: toolid}, callback);
}

export function getHistoriesBySiteId(siteid, callback) {
    callRPC("getHistoriesBySiteId", {siteid: siteid}, callback);
}

//----------------------------------------  UTILS  ---------------------------------------------

export function lendTool(toolid, siteid, employee, date, amount, callback) {
    callRPC("lendTool", {
        toolid: toolid,
        siteid: siteid,
        employee: employee,
        date: date,
        amount: amount,
    }, callback);
}

export function returnTool(toolid, siteid, employee, date, amount, callback) {
	callRPC("returnTool", {
        toolid: toolid,
        siteid: siteid,
        employee: employee,
        date: date,
        amount: amount,
    }, callback);
}

export function validatepw(pw, callback){
    callRPC("validatepw", {
        pw: pw
    }, callback);
}