import React  from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import './App.css';
import {Button, Table, Alert, Modal, Form, Dropdown, FormControl, Card, Container, Col, Row, Accordion} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import {BottomNav} from "./BottomNav";
import {ReturnToolComp} from "./ReturnTool";
 
const cookies = new Cookies();
const API = require('./API');
const util = require('./Util');

export class Site_ extends React.Component{

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };

    constructor(){
        super();
        this.state = {
            categories: [],
            histories: null,
            site: null,
            tools: [],
            showNewModal: false,
            showAlert: false,
            alertMessage: "",
            selectedCategory: null,
            selectedAmount: 1,
            selectedTool: null,
            showReturnAmountModal: false,
            selectedReturnAmount: 0,
            timer: null
        }
    }

    componentDidMount(){
        this.query();
        this.setState({
            timer: window.setInterval(() => {this.query()}, util.interval)
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.timer);
    }

    query(){
        var params = new URLSearchParams(this.props.location.search);
        var id = params.get("id");
        API.getAllCategories((categories) => {this.setState({categories: categories["data"]})});
        API.getSiteById(id, (resp) => {this.setState({site: resp["data"][0]})});
        API.getHistoriesBySiteId(id, (resp) => {console.log(this.sortHistories(resp["data"]));this.setState({histories: this.sortHistories(resp["data"])})});
        API.getAllTools((resp) => {this.setState({tools: util.sortByName(resp["data"])})});
    }

    sortHistories(histories){
        if (histories === undefined){
            return null;
        }

        // Sortieren nach Wert
        return histories.sort((a,b) => {
            var doneA = a["AMOUNT"] <= 0;
            var doneB = b["AMOUNT"] <= 0;

            var nameA = Math.min(...(a["transactions"].map((x) => x["DATE"])));
            var nameB = Math.min(...(b["transactions"].map((x) => x["DATE"])));
    
            if(doneA > doneB){
                return 1;
            }
            else if(doneA < doneB){
                return -1;
            }
            else{
                if (nameA > nameB) {
                    return -1;
                }
                else if (nameA < nameB) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }

    showNewModal(){
        this.setState({
            selectedAmount: 1,
            selectedCategory: this.state.categories.length > 0 ? this.state.categories[0] : null,
            selectedTool: this.state.tools.length > 0 ? this.state.tools.find((x) => x["CATEGORY_ID"] === this.state.categories[0]["ID"]) : null,
            showNewModal: true
        });
    }

    onRent(){
        if(this.state.selectedAmount > parseInt(this.state.selectedTool["FREE"])){
            this.setState({
                showAlert: true,
                alertMessage: "Anzahl ist leider nicht verfügbar"
            });
        } else if(this.state.selectedAmount < 1){
            this.setState({
                showAlert: true,
                alertMessage: "Bitte gib eine Anzahl >= 1 ein"
            });
        } else {
            API.lendTool(
                parseInt(this.state.selectedTool["ID"]), 
                parseInt(this.state.site["ID"]), 
                cookies.get("name"), 
                parseInt(Date.now() / 1000), 
                this.state.selectedAmount, 
                () => {this.onRentCompletion()}
                );
            this.setState({showNewModal: false});
        }
    }

    onRentCompletion(){
        this.query();
    }

    onReturnTool(){
        this.query();
    }

    onCategorySelect(category){
        this.setState({selectedCategory: category});
        this.setState({selectedTool: this.state.tools.find((x) => x["CATEGORY_ID"] === category["ID"])});
    }

    render(){
        return (
            <div>
                    <Table fluid className="text-light bg-dark">
                        <tbody>
                        <tr>
                            <td className="align-middle text-left border-top-0">
                                <h5 className="mb-0">{this.state.site ? this.state.site["NAME"] : ""}</h5>
                            </td>
                            <td className="align-middle text-right border-top-0">
                                <Button variant="outline-success" onClick={() => this.showNewModal()}>
                                    Werkzeug ausleihen
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <div style={{'padding-bottom': '80px'}}>
                        <Accordion className="mx-3">
                            {this.state.histories ? this.state.histories.map(history =>
                                <Card className="mx-auto">
                                    <util.CustomToggleAcc eventKey={String(history["TOOL_ID"])} name={history["TOOL_NAME"]} taken={history["AMOUNT"]}/>
                                    <Accordion.Collapse eventKey={String(history["TOOL_ID"])}>
                                        <Card.Body style={{padding: "0"}}>
                                            <Table style={{marginBottom: "0"}}>
                                                <tbody>
                                                    <tr>
                                                        <th className="text-left">Ausgeliehen</th>
                                                    </tr>
                                                    {history["transactions"].filter((transaction) => transaction["AMOUNT"] > 0 && transaction["TYPE"] == 0).map((transaction)=>
                                                        <tr>
                                                            <td className="w-100">
                                                            <Container fluid>
                                                                <Row>
                                                                    <Col>
                                                                        {transaction["EMPLOYEE"]}
                                                                    </Col>
                                                                    <Col>
                                                                        {util.from_timetamp(transaction["DATE"])}
                                                                    </Col>
                                                                    <Col>
                                                                        {transaction["AMOUNT"]}
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <th className="text-left">Zurückgegeben</th>
                                                    </tr>
                                                    {history["transactions"].filter((transaction) => transaction["AMOUNT"] < 0 && transaction["TYPE"] == 1).map((transaction)=>
                                                        <tr>
                                                            <td>
                                                                <Container fluid>
                                                                    <Row>
                                                                        <Col>
                                                                            {transaction["EMPLOYEE"]}
                                                                        </Col>
                                                                        <Col>
                                                                            {util.from_timetamp(transaction["DATE"])}
                                                                        </Col>
                                                                        <Col>
                                                                            {-1 * transaction["AMOUNT"]}
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {history["transactions"].filter((transaction) => transaction["TYPE"] == 2).length > 0 ?
                                                    <tr>
                                                        <th className="text-left">Überschuss</th>
                                                    </tr>
                                                    : ""}
                                                    {history["transactions"].filter((transaction) => transaction["TYPE"] == 2).map((transaction)=>
                                                        <tr>
                                                            <td>
                                                                <Container fluid>
                                                                    <Row>
                                                                        <Col>
                                                                            {transaction["EMPLOYEE"]}
                                                                        </Col>
                                                                        <Col>
                                                                            {util.from_timetamp(transaction["DATE"])}
                                                                        </Col>
                                                                        <Col>
                                                                            {transaction["AMOUNT"]}
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {history["AMOUNT"] > 0 ? 
                                                        <tr>
                                                            <td>
                                                                <ReturnToolComp key={history["TOOL_ID"] + "&" + history["SITE_ID"]} toolid={history["TOOL_ID"]} siteid={history["SITE_ID"]} amount={history["AMOUNT"]} onReturn={() => this.onReturnTool()}/>
                                                            </td>
                                                        </tr>
                                                    : "" }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ) : "Keine Einträge vorhanden"}
                        </Accordion>
                    </div>
                    <Modal show={this.state.showNewModal} onHide={() => {this.setState({showNewModal: false})}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Werkzeug ausleihen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Alert variant="danger" show={this.state.showAlert} onClose={() => this.setState({showAlert: false})} dismissible>
                            {this.state.alertMessage}
                        </Alert>
                        <Form.Label>Kategorie</Form.Label>
                        <Dropdown id="dropdown-basic-button" >
                            <Dropdown.Toggle as={util.CustomToggle} id="dropdown-custom-1" className="mr-1">
                                {this.state.selectedCategory ? this.state.selectedCategory["NAME"] : "Keine gefunden"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="outline-success" >
                                {this.state.categories ? this.state.categories.map((row) => 
                                    <Dropdown.Item onSelect={() => this.onCategorySelect(row)} eventKey={row["ID"]} >{row["NAME"]}</Dropdown.Item>
                                ) : "" }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Label>Werkzeug</Form.Label>
                        <Dropdown id="dropdown-basic-button" className="mr-1" >
                            <Dropdown.Toggle variant="outline-success" as={util.CustomToggle}>
                                {this.state.selectedTool ? this.state.selectedTool["NAME"] : "Keine gefunden"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="outline-success" as={util.CustomMenu}>
                            {this.state.tools ? this.state.tools
                                .filter((row) => (row["CATEGORY_ID"] === this.state.selectedCategory?.ID))
                                .map((row) => 
                                <Dropdown.Item onSelect={(e) => {this.setState({selectedTool: row})}} eventKey={row["ID"]} >{row["NAME"]}</Dropdown.Item>
                            ) : "" }
                            </Dropdown.Menu>
                        </Dropdown>
                        {this.state.selectedTool ?
                            this.state.selectedTool["FREE"] > 0 ? 
                                this.state.selectedTool["AMOUNT"] > 1 ?
                                    <>
                                        <Form.Label>Menge (verfügbar {this.state.selectedTool["FREE"]}/{this.state.selectedTool["AMOUNT"]})</Form.Label>
                                        <FormControl type="number" value={this.state.selectedAmount} onChange={(e) => this.setState({selectedAmount: e.target.value})} />
                                    </>
                                : "(verfügbar " + this.state.selectedTool["FREE"] + "/" + this.state.selectedTool["AMOUNT"] + ")"
                            : "Leider nichts verfügbar (verfügbar " + this.state.selectedTool["FREE"] + "/" + this.state.selectedTool["AMOUNT"] + ")"
                        : ""
                        }
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showNewModal: false})}>
                            Abbruch
                        </Button>
                        {(this.state.selectedTool && this.state.selectedTool["FREE"] > 0) ?
                            <Button variant="primary" onClick={() => this.onRent()}>
                                Ausleihen
                            </Button>
                        : "" }
                        </Modal.Footer>
                    </Modal>
                <BottomNav path="/sites"/>
            </div>
        );
    }
}



export const Site = withRouter(Site_);
  