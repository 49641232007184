import React, {useState, useContext} from 'react';
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import {FormControl, Button, AccordionContext, Card, Table} from 'react-bootstrap';

import { IconContext } from "react-icons";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";

export var interval = 10000;

// Performance measurement functions
var tictime;
if (!window.performance || !performance.now) { window.performance = { now: Date.now } }
export function tic() { tictime = performance.now() }
export function toc(msg) {
  var dt = performance.now() - tictime;
  console.log((msg || 'toc') + ": " + dt + "ms");
}

export function from_timetamp(timestamp){
    var d= new Date(timestamp * 1000);
    var datestring = ("0" + d.getDate()).slice(-2) + "." 
                + ("0"+(d.getMonth()+1)).slice(-2) + "."
                + d.getFullYear();
    return datestring;
}

export function to_timetamp(str){
    
}

export function sortByName(container){
    if(container === undefined || container === null){
      return [];
    } 
    // Sortieren nach Wert
    return container.sort((a,b) => {
        var nameA = a["NAME"];
        var nameB = b["NAME"];
        nameA = nameA.toUpperCase();
        nameB = nameB.toUpperCase();
        if (nameA > nameB) {
            return 1;
        }
        else if (nameA < nameB) {
            return -1;
        } else {
            return 0;
        }
    });
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      className="w-100 dropdown-toggle"
      variant="outline-success"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));
  
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          className="mx-3 my-2 w-auto"
          placeholder="Suche..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);


export function onRenderCallback(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) {
  // Aggregate or log render timings...
  console.log(id + " phase: " + phase + " actualDuration: " + actualDuration + " baseDuration: " + 
    baseDuration + " startTime: " + startTime + " commitTime: " + commitTime + " interactions: " + interactions);
}


export function CustomToggleAcc({eventKey, name, taken}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
      <Card.Header onClick={decoratedOnClick}>
          <Table className="mb-0" style={{tableLayout: "fixed"}}>
            <tbody>
              <tr>
                  <td className="p-0 text-left border-top-0 align-middle" style={{width: "45%"}}>
                      <h5 className="mb-0">{name}</h5>
                  </td>
                  <td className="p-0 text-left border-top-0 align-middle" style={{width: "45%"}}>
                      {taken > 0 ? "Auf Baustelle: " + taken : ""}
                  </td>
                  <td className="p-0 w-auto text-right border-top-0" style={{width: "10%"}}>
                      <IconContext.Provider value={{ size: "2em", className: "global-class-name" }}>
                          <div>
                              {isCurrentEventKey ? <RiArrowDropDownLine/> : <RiArrowDropRightLine/>}
                          </div>
                      </IconContext.Provider>
                  </td>
              </tr>
            </tbody>
          </Table>
      </Card.Header>
  );
}