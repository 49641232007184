import React, {useEffect, useState} from 'react';
import './App.css';
import {Button, Table, Alert, Modal, FormControl, Container, Col, Row} from 'react-bootstrap';
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
const API = require('./API');

export function ReturnToolComp(props) {
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [amount, setAmount] = useState(parseInt(props.amount));
    const [btnDisable, setBtnDisable] = useState(false);

    useEffect(()=>{
      setAmount(props.amount);
    }, [props.amount]);

    const overallAmount = parseInt(props.amount);
  
    const handleClose = () => {
      setBtnDisable(false);
      setShow(false)
    };
    const handleShow = () => {
        setBtnDisable(true);
        console.log(amount);
        if(amount > 1){
            setShow(true);
        } else {
            console.log("HI");
            onReturn(overallAmount);
        }
    }

    const onPartial = () => {
        if (amount === "" || amount <= 0){
            setShowAlert(true);
            setAlertMessage("Menge muss größer 0 sein");
        }
        if(amount <= overallAmount){
            onReturn(amount);
            handleClose();
        } else {
          if (window.confirm('Du gibst mehr zurück als ausgeliehen wurden. Fortfahren?')) {
            onReturn(amount);
            handleClose();
          }
        }
    }

    const onAll = () => {
        onReturn(overallAmount);
        handleClose();
    }

    const onReturn = (amount) => {
      API.returnTool(
        parseInt(props.toolid), 
        parseInt(props.siteid), 
        cookies.get("name"), 
        parseInt(Date.now() / 1000),  
        amount, 
        () => props.onReturn()
    );
    }
  
    return (
      <>
        <Button disabled={btnDisable} variant="primary" className="ml-2" onClick={handleShow}>
            Zurückgeben
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Menge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                {alertMessage}
            </Alert>
              <Table>
                  <tbody>
                      <tr>
                          <td className="pr-0">
                            <Container>
                              <Row>
                                <Col className="d-flex p-0 pr-1 justify-content-center">
                                  <FormControl type="number" value={amount} onChange={(e) => {setAmount(parseInt(e.target.value))}} style={{width: "15vh"}}/>
                                </Col>
                                <Col className="mx-auto p-0 pr-1 d-flex justify-content-center align-items-center" style={{whiteSpace: "nowrap"}}>
                                    von {props.amount}
                                </Col>
                              </Row>
                            </Container>
                          </td>
                          <td className="">
                            <Button variant="primary" onClick={onPartial}>
                                Zurückgeben
                            </Button>
                          </td>
                      </tr>
                      <tr>
                          <td colSpan="3">
                            <Button className="w-100" variant="primary" onClick={onAll}>
                                Alle Zurückgeben
                            </Button>
                        </td>
                      </tr>
                      <tr>
                          <td colSpan="3">
                            <Button className="w-100" variant="secondary" onClick={handleClose}>
                                Abbruch
                            </Button>
                          </td>
                      </tr>
                  </tbody>
              </Table>
          </Modal.Body>
        </Modal>
      </>
    );
  }