import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import { FaTools, FaWarehouse } from 'react-icons/fa';
import PropTypes from "prop-types";
import { withRouter } from "react-router";

export class BottomNav_ extends React.Component{

    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    };

    onClick(link){
      this.props.history.push(link);
    }
  
    render(){
      console.log(this.props.path);
      return (
        <Navbar bg="dark" variant="dark" fixed="bottom" className="d-block d-md-none">
          <Nav fill variant="dark tabs" defaultActiveKey={this.props.path}>
            <Nav.Item>
              <Nav.Link onClick={() => this.onClick("/tools")} eventKey="/tools">
                      <FaTools/><br/>
                      Werkzeuge
                      </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => this.onClick("/sites")} eventKey="/sites">
                <FaWarehouse/><br/>
                Baustellen
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      );
    }
  
  }

  export const BottomNav = withRouter(BottomNav_);